<template>
  <b-container fluid>
    <b-row>
      <b-col cols="10">
        <h1>{{ nomeEdital }}</h1>
      </b-col>
      <b-col cols="2">
        <b-button
          @click="[(modalCriarDisciplina = true)]"
          class="btn-icon"
          variant="success"
          block
        >
          <feather-icon icon="PlusIcon" />
          Nova Disciplina
        </b-button>
      </b-col>
    </b-row>
    <br />
    <div v-for="(disciplinas, idx) in disciplinasEdital" :key="idx">
      <b-row>
        <b-col cols="10"> </b-col>
        <b-col cols="2">
          <!--b-button :to="`/pages/edital-verticalizado/edital/${idEdital}/modo-edicao/disciplina/${disciplinas.id}`" variant="primary" block>
                        Editar disciplina
                    </b-button-->
        </b-col>
      </b-row>
      <br />
      <b-table
        striped
        hover
        :fields="fields"
        :items="disciplinas.topicos"
        :tbody-tr-class="rowClass"
        v-if="
          disciplinas.topicos.length > 0 ||
          disciplinas.usuario_edital_id != null
        "
      >
        <template #table-colgroup="scope">
          <col
            style="text-align: center"
            v-for="field in scope.fields"
            :key="field.key"
          />
        </template>
        <template #head(topicos)>
          {{ disciplinas.nome }}
          <a
            href="#"
            @click.prevent="
              [(modalInfoDisciplina = true), disciplinaInfo(disciplinas)]
            "
          >
            <feather-icon icon="InfoIcon" size="15" />
          </a>
          <b-button
            class="btn-icon"
            variant="flat-primary"
            :to="`/pages/edital-verticalizado/edital/${idEdital}/modo-edicao/disciplina/${disciplinas.id}`"
          >
            <feather-icon icon="SettingsIcon" size="15" />
          </b-button>
          <b-button
            v-if="disciplinas.reset"
            href="#"
            @click.prevent="
              [
                (modalResetDisciplina = true),
                (disciplinaResetInfo = disciplinas.nome),
                (currentResetDisciplinaId = disciplinas.id),
              ]
            "
            class="btn-icon"
            variant="flat-primary"
          >
            <feather-icon icon="RefreshCcwIcon" size="15" />
          </b-button>
        </template>
        <template #cell(aproveitamento)="row">
          <div @click="row.toggleDetails" style="text-align: center">
            <b-progress
              height="1.5rem"
              :variant="`${barColor(row.item.aproveitamento)}`"
              :value="row.item.aproveitamento"
              max="100"
              show-progress
              animated
            ></b-progress>
          </div>
        </template>

        <template #cell(topicos)="row">
          <b-row>
            <b-col>
              <div
                class="topico-nome"
                @click="row.toggleDetails"
                v-html="setEspacamentoTopico(row.item.nome)"
              ></div>
            </b-col>
          </b-row>
        </template>
        <template #row-details="row">
          <b-card>
            <b-card-text>
              <b-row>
                <b-col class="mb-2" cols="12">
                  <strong>
                    {{ row.item.nome }}
                  </strong>
                </b-col>
                <b-col cols="12">
                  <div>Aproveitamento - {{ row.item.aproveitamento }}</div>
                  <br />
                  <b-progress
                    height="1.5rem"
                    :variant="`${barColor(row.item.aproveitamento)}`"
                    :value="row.item.aproveitamento"
                    max="100"
                    show-progress
                    animated
                  ></b-progress>
                </b-col>
                <b-col cols="6">
                  <b-form-input
                    v-model="acertos"
                    class="mt-2"
                    placeholder="Acertos Ex.: 10"
                  ></b-form-input>
                </b-col>
                <b-col cols="6">
                  <b-form-input
                    v-model="erros"
                    class="mt-2"
                    placeholder="Erros Ex.: 5"
                  ></b-form-input>
                </b-col>
                <b-col cols="12">
                  <b-button
                    @click="cadastrarAcertosErros(row.item)"
                    class="mt-2"
                    variant="primary"
                    >Registrar</b-button
                  >
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
        </template>

        <template class="align-check" #cell(estudado)="data">
          <b-form-checkbox
            :disabled="data.item.data_finalizacao ? true : false"
            :checked="data.item.data_finalizacao ? true : false"
            @change="
              [
                checkEstudado(data.item, disciplinas.revisoes),
                resetDisciplinaTopicosChecked(disciplinas.id),
              ]
            "
            class="align-check"
          ></b-form-checkbox>
        </template>
        <!-- 
                    O trecho de código a seguir foi feito dessa forma para adequar ao JSON retornado
                 -->
        <template class="align-check" #cell(revisao24h)="data">
          <b-form-checkbox
            v-if="!data.item.rev_1.length"
            class="align-check"
            :disabled="true"
          ></b-form-checkbox>
          <b-form-checkbox
            v-b-tooltip.hover
            :title="data.item.rev_1[0].data"
            v-else
            @change="
              checkRevisoes(
                data.item,
                data.item.rev_1[0],
                disciplinas.revisoes[0]
              )
            "
            :class="isAtualData(data.item.rev_1[0].data)"
            :checked="checkSelectRevisoesChecked(data.item.rev_1[0])"
          ></b-form-checkbox>
        </template>
        <template class="align-check" #cell(revisao7dias)="data">
          <b-form-checkbox
            v-if="!data.item.rev_7.length"
            class="align-check"
            :disabled="true"
          ></b-form-checkbox>
          <b-form-checkbox
            v-b-tooltip.hover
            :title="data.item.rev_7[0].data"
            v-else
            @change="
              checkRevisoes(
                data.item,
                data.item.rev_7[0],
                disciplinas.revisoes[0]
              )
            "
            :class="isAtualData(data.item.rev_7[0].data)"
            :checked="checkSelectRevisoesChecked(data.item.rev_7[0])"
          ></b-form-checkbox>
        </template>
        <template class="align-check" #cell(revisao30dias)="data">
          <b-form-checkbox
            v-if="!data.item.rev_30.length"
            class="align-check"
            :disabled="true"
          ></b-form-checkbox>
          <b-form-checkbox
            v-b-tooltip.hover
            :title="data.item.rev_30[0].data"
            v-else
            @change="
              checkRevisoes(
                data.item,
                data.item.rev_30[0],
                disciplinas.revisoes[0]
              )
            "
            :class="isAtualData(data.item.rev_30[0].data)"
            :checked="checkSelectRevisoesChecked(data.item.rev_30[0])"
          ></b-form-checkbox>
        </template>

        <template #cell(incidencia)="data" style="width: 1rem; float: center">
          <div
            v-b-tooltip.hover
            :title="data.item.incidencia ? `${data.item.incidencia}%` : '0%'"
            :class="`${apriveitamentoColor(
              data.item.incidencia
            )} incidencia-card`"
          ></div>
        </template>
      </b-table>
    </div>
    <!-- MODAL NOVA DISCIPLINA -->
    <NovaDisciplinaEdital v-model="modalCriarDisciplina" />

    <!-- MODAL RESET DISCIPLINA -->
    <b-modal
      v-model="modalResetDisciplina"
      ok-title="Ok"
      cancel-title="Cancelar"
      ok-only
      title="Resetar tópicos da disciplina?"
      @ok="resetTopicosDisciplina()"
    >
      <b-col cols="12">
        <br />
        <b-row> {{ disciplinaResetInfo }}<br /> </b-row>
        <br />
        <b-row>
          <b-form-checkbox
            v-on:change="resetAproveitamento = !resetAproveitamento"
          >
            <span style="color: gray; font-size: 12px"
              >Resetar aproveitamento?</span
            >
          </b-form-checkbox>
        </b-row>
      </b-col>
    </b-modal>

    <b-modal
      v-model="modalInfoDisciplina"
      ok-title="Ok"
      cancel-title="Cancelar"
      ok-only
      title="Disciplina"
    >
      <b-row v-if="disciplinaInfoData">
        <b-col cols="12">
          <strong>Aproveitamento: </strong><br />
          {{ `${disciplinaInfoData.aproveitamento}%` }}
        </b-col>
        <b-col cols="12">
          <strong>Progresso: </strong><br />
          {{ `${disciplinaInfoData.progresso}%` }}
        </b-col>
        <b-col cols="12">
          <strong>Porcentagem da última prova: </strong><br />
          {{ `${disciplinaInfoData.porcentagem_ultima_prova}%` }}
        </b-col>
        <b-col cols="12">
          <strong>Finalizações: </strong><br />
          {{ `${disciplinaInfoData.finalizacoes}` }}
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>

<script>
import NovaDisciplinaEdital from "@/views/components/edital/NovaDisciplinaEdital";
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BContainer,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormInput,
  BIcon,
  BProgress,
  BRow,
  BTable,
  BTooltip,
  VBTooltip,
} from "bootstrap-vue";
import moment from "moment";
export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BTable,
    BContainer,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormInput,
    BButton,
    BCard,
    BCardText,
    BProgress,
    BIcon,
    BTooltip,
    NovaDisciplinaEdital,
  },
  data: () => ({
    modalInfoDisciplina: false,
    modalResetDisciplina: false,
    resetAproveitamento: false,
    disciplinaResetInfo: "ESSA INFO",
    colsTopicoNome: 6,
    colsEstudado: 1,
    colsTableRevisoes: 6,
    nomeEdital: "",
    disciplinasEdital: [],
    idEdital: null,
    topicosSelected: [],
    fields: [
      { key: "topicos", label: "Tópicos" },
      { key: "aproveitamento", label: "Aproveitamento" },
      { key: "estudado", label: "Estudado" },
      { key: "revisao24h", label: "Revisão de 24h" },
      { key: "revisao7dias", label: "Revisão de 7 dias" },
      { key: "revisao30dias", label: "Revisão de 30 dias" },
      { key: "incidencia", label: "Incidência" },
    ],
    acertos: "",
    erros: "",
    disciplinaInfoData: null,
    modalCriarDisciplina: false,
    currentResetDisciplinaId: null,
  }),
  methods: {
    moment,
    disciplinaInfo(topicos) {
      const { id } = topicos;
      this.$http
        .get(`/editais/${this.idEdital}/disciplinas/${id}/info`)
        .then((response) => {
          this.disciplinaInfoData = response.data;
        });
    },
    setEspacamentoTopico(value) {
      const numero_topico = value.split(" ")[0];
      const numeroTopico_splitted = numero_topico.split(".");
      let whiteSpace = "";
      for (let i = 0; i < numeroTopico_splitted.length - 1; i++) {
        whiteSpace = "__" + whiteSpace;
      }
      return `<span style="opacity: 0;">${whiteSpace}</span>${value}`;
    },
    isAtualData(data) {
      const dataParse = this.moment(data, "DD/MM/YYYY");
      return this.moment().isSame(dataParse, "days")
        ? "align-check shadow-check"
        : "align-check";
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (!item.topico_id) {
        return "topico-sem-id";
      }
    },
    resetTopicosDisciplina() {
      alert(this.resetAproveitamento);
      this.$http
        .post(
          `/editais/${this.idEdital}/disciplinas/${this.currentResetDisciplinaId}/topicos/reset`,
          { resetAproveitamento: this.resetAproveitamento }
        )
        .finally(() => {
          this.getDisciplinasEdital();
        });
    },
    resetDisciplinaTopicosChecked(idDisciplina) {
      this.currentResetDisciplinaId = idDisciplina;
      this.disciplinasEdital.map((disciplina, idx) => {
        let indexDisciplina = idx;

        let qtdFinalizado = disciplina.topicos.filter((topico) => {
          if (topico.data_finalizacao) {
            return topico;
          }
        });

        let allFinalizado =
          qtdFinalizado.length -
          this.disciplinasEdital[indexDisciplina].topicos.length;

        if (
          (allFinalizado == 1 || allFinalizado == -1) &&
          idDisciplina == disciplina.id
        ) {
          this.disciplinasEdital[indexDisciplina].reset = true;
          this.modalResetDisciplina = true;
          this.disciplinaResetInfo =
            this.disciplinasEdital[indexDisciplina].nome;
        }
      });
    },
    resetDisciplinaTopicos() {
      this.disciplinasEdital.map((disciplina, idx) => {
        let indexDisciplina = idx;

        let qtdFinalizado = disciplina.topicos.filter((topico) => {
          if (topico.data_finalizacao) {
            return topico;
          }
        });

        let allFinalizado =
          qtdFinalizado.length ==
          this.disciplinasEdital[indexDisciplina].topicos.length;
        if (allFinalizado) {
          this.disciplinasEdital[indexDisciplina].reset = true;
        }
      });
    },
    async getDisciplinasEdital() {
      await this.$http
        .get(`/editais/${this.idEdital}/disciplinas`)
        .then((response) => {
          this.nomeEdital = response.data.nome;
          this.disciplinasEdital = response.data.disciplinas;
          // Setando chave para controle de reset
          this.disciplinasEdital = this.disciplinasEdital.map((disciplina) => {
            return {
              ...disciplina,
              reset: false,
            };
          });
        })
        .finally(() => {
          this.resetDisciplinaTopicos();
        });
    },
    checkEstudado(topico, revisoes) {
      const { id, disciplina_id, data_finalizacao, topico_id } = topico;

      let check = data_finalizacao ? 0 : 1;

      this.$http
        .put(
          `/editais/${this.idEdital}/disciplinas/${disciplina_id}/topicos/${id}/edit`,
          {
            checkbox: check,
            revisoes: revisoes,
            topico_id: topico_id,
          }
        )
        .then((response) => {
          this.getDisciplinasEdital();
        });
    },
    apriveitamentoColor(aproveitamento) {
      if (!aproveitamento) {
        return "bg-purple-1";
      }

      if (aproveitamento < 5) {
        return "bg-purple-1";
      } else if (aproveitamento < 11) {
        return "bg-purple-2";
      } else if (aproveitamento < 16) {
        return "bg-purple-3";
      } else if (aproveitamento < 21) {
        return "bg-purple-4";
      } else {
        return "bg-purple-5";
      }
    },
    barColor(aproveitamento) {
      if (!aproveitamento) {
        return "danger";
      }

      if (aproveitamento < 30) {
        return "danger";
      }

      if (aproveitamento >= 31) {
        return "success";
      }
    },
    checkIntervaloUmDia(revisaoData) {
      if (typeof revisaoData[0] === "undefined") {
        return true;
      } else {
        return false;
      }
    },
    checkIntervaloSeteDias(revisaoData) {
      if (typeof revisaoData[0] === "undefined") {
        return true;
      } else {
        return false;
      }
    },
    checkEstudadoStatus(data_finalizacao) {
      if (data_finalizacao) {
        return true;
      }
    },
    checkIntervaloTrintaDias(revisaoData) {
      if (typeof revisaoData[0] === "undefined") {
        return true;
      } else {
        return false;
      }
    },
    revisaoExists(revisaoData) {
      if (revisaoData.length > 0) {
        return true;
      }
      return false;
    },
    pad2(n) {
      return (n < 10 ? "0" : "") + n;
    },
    checkSelectRevisoesChecked(revisaoData) {
      return revisaoData.data_finalizacao ? true : false;
    },
    checkSelectRevisoesDisabled(revisaoData) {
      const splitted_date = revisaoData.data.split("/");
      const rev_data = new Date(
        splitted_date[2],
        splitted_date[1] - 1,
        splitted_date[0]
      );

      const timeElapsed = Date.now();
      const hoje = new Date(timeElapsed);

      const res = this.dateCompare(
        hoje.toLocaleDateString(),
        rev_data.toLocaleDateString()
      );
      return res;
    },
    checkRevisoes(topico, revisaoData, disciplina_revisoes) {
      const { id, disciplina_id } = topico;
      const { id: revisao_id, data_finalizacao } = revisaoData;

      let check = data_finalizacao ? 0 : 1;

      let route = `/editais/${this.idEdital}/disciplinas/${disciplina_id}/topicos/${id}/revisoes/${revisao_id}/edit`;
      this.$http
        .put(route, {
          checkbox: check,
          recorrente: disciplina_revisoes.recorrente,
        })
        .then((response) => {
          this.getDisciplinasEdital();
        });
    },
    cadastrarAcertosErros(topico) {
      const { id, disciplina_id } = topico;

      let route = `/editais/${this.idEdital}/disciplinas/${disciplina_id}/topicos/${id}/edit/aproveitamento`;
      this.$http
        .put(route, {
          acertos: this.acertos,
          erros: this.erros,
        })
        .then((response) => {
          this.getDisciplinasEdital();
          this.acertos = null;
          this.erros = null;
        });
    },
    dateCompare(d1, d2) {
      const date1 = new Date(d1);
      const date2 = new Date(d2);

      if (date1 >= date2) {
        return true;
      } else if (date1 < date2) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.idEdital = this.$route.params.id;
    this.getDisciplinasEdital();
  },
};
</script>

<style>
.bg-purple-1,
.bg-purple-2,
.bg-purple-3,
.bg-purple-4 {
  color: #ffffff !important;
}
.bg-purple-1 {
  background-color: #ba55d3;
  opacity: 0.5;
}

.bg-purple-2 {
  background-color: #9932cc;
  opacity: 0.5;
}

.bg-purple-3 {
  background-color: #9400d3;
  opacity: 0.5;
}

.bg-purple-4 {
  background-color: #8a2be2;
  opacity: 0.5;
}

.bg-purple-5 {
  background-color: #4b0082;
  opacity: 0.5;
}

.align-check {
  max-width: 18px;
  margin: auto auto;
}
/* 
tr:nth-child(even) {
  background-color: #dddddd;
} */

.incidencia-card {
  width: 20px;
  height: 20px;
  border-radius: 0.2em;
  margin: auto auto;
  color: #000000;
  text-align: center;
  vertical-align: center;
  padding: 8px 0 0 0;
}

.topico-nome {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.topico-sem-id {
  border-top: solid rgba(115, 103, 240, 0.12) 2px !important;
  border-bottom: solid rgba(115, 103, 240, 0.12) 2px !important;
}

.shadow-check {
  max-width: 25px !important;
  padding: 0 !important;
  border-radius: 7px;
  /* box-shadow: 5px 5px 10px #7367f0 !important; */
  border: rgba(115, 103, 240, 0.7) solid 3px !important;
  background-color: transparent !important;
}
</style>
