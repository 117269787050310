<template>
    <div>
        <b-modal
            ok-title="Ok"
            cancel-title="Cancelar"
            title="Nova Disciplina"
            v-model="model"
            @ok="novaDisciplinaToEdital()"
            >
            <b-card-text>
                <b-form-select v-model="novaDisiplinaID" :options="disciplinas" value-field="id" text-field="nome"></b-form-select>
            </b-card-text>
        </b-modal>
    </div>
</template>

<script>
import {
    BFormSelect,
    BCardText
} from 'bootstrap-vue'
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    components:{
        BFormSelect,
        BCardText
    },
    computed:{
        model: {
            get(){
                return this.value
            },
            set(value){
                this.$emit('input', value)
            }
        }
    },
    data: () => ({
        modalCriarDisciplina: false,
        idEdital: null,
        novaDisiplinaID: null,
        disciplinas: []
    }),
    methods: {
        novaDisciplinaToEdital(){
            this.$http.post(`/editais/${this.idEdital}/disciplinas/storePrivate`,
                {
                    disciplina_id: this.novaDisiplinaID
                }
            ).then( response => {
                let disciplinaId = response.data

                this.$router.push(`/pages/edital-verticalizado/edital/${this.idEdital}/modo-edicao/disciplina/${disciplinaId}`)
                // location.reload()
            })
        },
        getAllDisciplinas() {
			this.$http.get("disciplinas").then((response) => {
				this.disciplinas = response.data;
			});
		},
    },
    mounted(){
        this.idEdital = this.$route.params.id
        this.getAllDisciplinas()
    }
}
</script>

<style>

</style>